import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../../utils/api'
import { useAuthStore } from '../auth'
import { useMenuCardsStore } from './menu-cards'
import { useMenuCategoriesStore } from './menu-categories'
import { useMenuItemsStore } from './menu-items'
import { useMenuOptionGroupsStore } from './menu-option-groups'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    // menu: {
    //   menus: [],
    //   cards: [],
    //   categories: [],
    //   items: [],
    //   option_groups: [],
    //   recommendations: [],
    // },
    menus: [],
    recommendations: [],
    menuHasLoadedValue: false,
    menuIsLoadingValue: false,
    tempImageURL: import.meta.env.VITE_APP_SERVER_URL + 'temp/images/',
  }),

  getters: {
    menuIsLoading: (state) => state.menuIsLoadingValue || !state.menuHasLoadedValue,
    menusOfCurrentRestaurant(state) {
      const authStore = useAuthStore()
      return state.menus
        .filter((x) => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    recommendationsOfCurrentRestaurant(state) {
      const authStore = useAuthStore()
      if (!Array.isArray(state.recommendations)) return []
      return state.recommendations
        .filter((x) => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    recommendationsNotSupportedForSubscription: (state) => !Array.isArray(state.recommendations),
    menusMap(state) {
      return new Map(this.menusOfCurrentRestaurant.map((element) => [element.id, element]))
    },
  },

  actions: {
    async getInventory() {
      return await GET(`accounts/:accountId/inventory/:restaurantId`)
    },

    async updateInventory(changes) {
      return await POST(`accounts/:accountId/inventory/:restaurantId`, changes)
    },

    async getTempImage(params) {
      return await GET(`temp/images/${params[0]}/${params[1]}`, {
        responseType: 'blob',
        timeout: 30000,
      })
    },

    async getMenu() {
      this.menuHasLoadedValue = false
      this.menuIsLoadingValue = true
      try {
        const response = await GET(`accounts/:accountId/menu/:restaurantId`)
        
        // Process the response data
        const { menus, cards, categories, items, option_groups, recommendations } = response

        // Create maps for efficient lookups
        // const cardsMap = new Map(cards.map(card => [card.id, card]))
        // const categoriesMap = new Map(categories.map(category => [category.id, category]))
        // const itemsMap = new Map(items.map(item => [item.id, item]))
        // const optionGroupsMap = new Map(option_groups.map(group => [group.id, group]))

        // Set up parent-child relationships
        // menus.forEach(menu => {
        //   menu.cards = menu.cards.map(cardId => cardsMap.get(cardId))
        // })

        cards.forEach(card => {
          // card.menus = menus.filter(menu => menu.cards.includes(card.id))
          card.menus = menus.filter(menu => menu.cards.includes(card.id)).map(menu => menu.id)
          // card.categories = card.categories.map(categoryId => categoriesMap.get(categoryId))
        })

        categories.forEach(category => {
          category.cards = cards.filter(card => card.categories.includes(category.id)).map(card => card.id)
          // category.items = category.items.map(itemId => itemsMap.get(itemId))
        })

        items.forEach(item => {
          item.categories = categories.filter(category => category.items.includes(item.id)).map(category => category.id)
          item.optionGroupParent = option_groups.filter(group => group.items.includes(item.id)).map(group => group.id)
          // item.option_groups = item.option_groups.map(groupId => optionGroupsMap.get(groupId))
        })

        option_groups.forEach(group => {
          group.parentItem = items.filter(item => item.option_groups.includes(group.id)).map(item => item.id)
          // group.items = group.items.map(itemId => itemsMap.get(itemId))
        })

        // Update stores with processed data
        const menuCardsStore = useMenuCardsStore()
        menuCardsStore.cards = cards

        const menuCategoriesStore = useMenuCategoriesStore()
        menuCategoriesStore.categories = categories

        const menuItemsStore = useMenuItemsStore()
        menuItemsStore.items = items

        const menuOptionGroupsStore = useMenuOptionGroupsStore()
        menuOptionGroupsStore.optionGroups = option_groups

        this.menus = menus
        this.recommendations = recommendations
        // this.menu.menus = menus
        // this.menu.cards = cards
        // this.menu.categories = categories
        // this.menu.items = items
        // this.menu.option_groups = option_groups
        // this.menu.recommendations = recommendations
        this.menuHasLoadedValue = true
        this.menuIsLoadingValue = false
        return response
      } catch (error) {
        console.error(error)
        this.menuIsLoadingValue = false
        throw error
      }
    },

    async getMenuIfUndefined() {
      if (!this.menuHasLoadedValue && !this.menuIsLoadingValue) {
        return await this.getMenu()
      }
      return null
    },

    async getMenuChangelog(params) {
      const paramsString = params ? `&${params}` : ''
      return await GET(`accounts/:accountId/menu/:restaurantId/changes?page=1&results=100${paramsString}`)
    },

    async newRecommendation(recommendation) {
      const response = await POST(`accounts/:accountId/menu/recommendations`, recommendation)
      let item_index = this.recommendations.findIndex(
        (x) => x.item_id === recommendation.item_id
      )
      let recommendation_index = this.recommendations[item_index].recommendations.findIndex(
        (x) => x.item_id === recommendation.recommendation_id
      )
      this.recommendations[item_index].recommendations[recommendation_index].id = recommendation.id

      // TODO this.menu.items is not used right now and items is in a different store
      item_index = this.menu.items.findIndex(
        (item) => item.id === recommendation.item_id
      )
      this.menu.items[item_index].recommendations.push(recommendation.recommendation_id)
      return response
    },

    async deleteRecommendation(recommendation) {
      const response = await DELETE(`accounts/:accountId/menu/recommendations/${recommendation.item_id}/${recommendation.recommendation_id}`)
      // TODO this.menu.items is not used right now and items is in a different store
      let item_index = this.menu.items.findIndex(
        (item) => item.id === recommendation.item_id
      )
      let recommendation_index = this.menu.items[item_index].recommendations.indexOf(recommendation.recommendation_id)
      this.menu.items[item_index].recommendations.splice(recommendation_index, 1)
      return response
    },

    async newMenu(menu) {
      const response = await POST(`accounts/:accountId/menu/menus`, menu)
      this.menus.push(menu)
      
      // Update the card-menu relationships when a new menu is created
      const menuCardsStore = useMenuCardsStore()
      menuCardsStore.updateCardMenuRelationships(menu.id, menu.cards || [], 'add')

      return response
    },

    async updateMenu(menuObj) {
      const response = await PUT(`accounts/:accountId/menu/menus/${menuObj.id}`, menuObj)
      const menuName = this.menusMap.get(menuObj.id)?.name
      
      const index = this.menus.findIndex((x) => x.id === menuObj.id)
      if (index !== -1) {
        // Update only the properties that were changed
        Object.assign(this.menus[index], menuObj)

        // If 'cards' property was updated, update the card-menu relationships
        if ('cards' in menuObj) {
          const menuCardsStore = useMenuCardsStore()
          menuCardsStore.updateCardMenuRelationships(menuObj.id, menuObj.cards, 'set')
        }
      }
      return response
    },

    async deleteMenu(menuId) {
      const response = await DELETE(`accounts/:accountId/menu/menus/${menuId}`)
      const menuName = this.menusMap.get(menuId)?.name
      this.menus = this.menus.filter((menu) => menu.id !== menuId)

      // Update the card-menu relationships when a menu is deleted
      const menuCardsStore = useMenuCardsStore()
      menuCardsStore.updateCardMenuRelationships(menuId, [], 'remove')

      return response
    },
  },
})
