import { computed } from 'vue'
import { useRestaurantsStore } from '@/stores/restaurants'

const currencyFilterConfig = {
  USD: {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  EUR: {
    symbol: '€',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  MXN: {
    symbol: '$',
    thousandsSeparator: ',',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  GBP: {
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
  EGP: {
    symbol: '£',
    thousandsSeparator: ',',
    fractionCount: 2,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
  },
}

function formatCurrency(value, config) {
  if (typeof value !== 'number') {
    return ''
  }

  const { symbol, thousandsSeparator, fractionCount, fractionSeparator, symbolPosition, symbolSpacing } = config
  const absValue = Math.abs(value)
  const formattedNumber = absValue.toFixed(fractionCount).replace(/\d(?=(\d{3})+\.)/g, `$&${thousandsSeparator}`)
  const [integerPart, fractionalPart] = formattedNumber.split('.')

  let result = integerPart
  if (fractionCount > 0) {
    result += fractionSeparator + fractionalPart
  }

  if (symbolPosition === 'front') {
    result = symbol + (symbolSpacing ? ' ' : '') + result
  } else {
    result = result + (symbolSpacing ? ' ' : '') + symbol
  }

  return value < 0 ? `-${result}` : result
}

export function useRestaurantCurrency() {
  const restaurantsStore = useRestaurantsStore()
  
  const currentRestaurantCurrency = computed(() => restaurantsStore.currentRestaurant?.currency || 'EUR')
  
  const restaurantCurrency = (value) => {
    const config = currencyFilterConfig[currentRestaurantCurrency.value] || currencyFilterConfig['EUR']
    return formatCurrency(value, config)
  }
  
  const restaurantCurrencyCompact = (value) => {
    const config = {
      ...currencyFilterConfig[currentRestaurantCurrency.value] || currencyFilterConfig['EUR'],
      fractionCount: 0
    }
    return formatCurrency(value, config)
  }

  return {
    restaurantCurrency,
    restaurantCurrencyCompact
  }
}

// Exporting named functions for backward compatibility
export const restaurantCurrency = (value) => {
  const { restaurantCurrency: currencyFn } = useRestaurantCurrency()
  return currencyFn(value)
}

export const restaurantCurrencyCompact = (value) => {
  const { restaurantCurrencyCompact: currencyCompactFn } = useRestaurantCurrency()
  return currencyCompactFn(value)
}
