import { defineStore } from 'pinia'
import { POST, PUT, DELETE } from '../../utils/api'
import { toast } from 'vue-sonner'
import { useAuthStore } from '../auth'
import { useMenuCategoriesStore } from '../menu/menu-categories'

export const useMenuCardsStore = defineStore('menuCards', {
  state: () => ({
    cards: [],
  }),

  getters: {
    cardsOfCurrentRestaurant() {
      const authStore = useAuthStore()
      return this.cards
        .filter((x) => x.restaurant_id === authStore.restaurantId)
        .sort((a, b) => a.id - b.id)
    },
    cardsMap() {
      return new Map(
        this.cardsOfCurrentRestaurant.map((element) => [element.id, element])
      )
    },
  },

  actions: {
    async newCard(card) {
      try {
        const response = await POST('accounts/:accountId/menu/cards', card)
        this.cards.push(response.card)
        return response.card
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async updateCard(cardArray) {
      try {
        const response = await PUT('accounts/:accountId/menu/cards', { cards: cardArray })
        
        cardArray.forEach(updatedCard => {
          const index = this.cards.findIndex(card => card.id === updatedCard.id)
          if (index !== -1) {
            // Use Vue's reactivity system to update the array
            this.cards[index] = { ...this.cards[index], ...updatedCard }
          }
        })
        
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async deleteCard(cardId) {
      try {
        const response = await DELETE(`accounts/:accountId/menu/cards/${cardId}`)
        const cardName = this.cardsMap.get(cardId)?.name
        this.cards = this.cards.filter(card => card.id !== cardId)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    // New helper method to update card-menu relationships
    updateCardMenuRelationships(menuId, cardIds, action) {
      // action can be 'add', 'remove', or 'set'
      this.cards.forEach(card => {
        const cardInMenu = cardIds.includes(card.id)
        const menuIndex = card.menus.indexOf(menuId)

        if (action === 'add' && cardInMenu && menuIndex === -1) {
          card.menus.push(menuId)
        } else if (action === 'remove' && !cardInMenu && menuIndex !== -1) {
          card.menus.splice(menuIndex, 1)
        } else if (action === 'set') {
          if (cardInMenu && menuIndex === -1) {
            card.menus.push(menuId)
          } else if (!cardInMenu && menuIndex !== -1) {
            card.menus.splice(menuIndex, 1)
          }
        }
      })
    },

    async addCategoriesToCards(cardIds, categoryIds) {
      try {
        // Get current cards data
        const cardsToUpdate = cardIds.map(cardId => {
          const card = this.cardsMap.get(Number(cardId))
          return {
            id: cardId,
            categories: [...new Set([...(card.categories || []), ...categoryIds])]
          }
        })

        // Update all cards in one PUT call
        const response = await this.updateCard(cardsToUpdate)

        // Update the category-card relationships
        const menuCategoriesStore = useMenuCategoriesStore()
        menuCategoriesStore.updateCategoryCardRelationships(cardIds, categoryIds, 'add')

        toast.success(`Categories added to ${cardIds.length} ${cardIds.length === 1 ? 'card' : 'cards'}`)
        return response
      } catch (error) {
        console.error('Failed to add categories to cards:', error)
        toast.error('Failed to add categories to cards')
        throw error
      }
    },
  },
})
