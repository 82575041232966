<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
  noOverflow?: boolean
}>()
</script>

<template>
  <div :class="['relative w-full', { 'overflow-auto': !props.noOverflow }]">
    <table :class="cn('w-full caption-bottom text-sm border-collapse', props.class)">
      <slot />
    </table>
  </div>
</template>
