import { defineStore } from 'pinia'
import { GET, POST, PUT } from '../utils/api'
import router from '../routes/starterRouter'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { useAccountsStore } from './accounts'
import { useBillingStore } from './billing'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: localStorage.getItem('jwt') || '',
    status: '',
    adminImpersonateRole: null,
    user: {
      completed: false,
      email: null,
      first_name: null,
      is_admin: undefined,
      is_moderator: undefined,
      is_reseller: undefined,
      last_name: null,
      phone_number: null,
    },
    loginDetails: {},
    loggedIn: undefined,
    accountId: parseInt(localStorage.getItem('accountId')) || undefined,
    restaurantId: parseInt(localStorage.getItem('restaurantId')) || undefined,
    restaurantCurrency: localStorage.getItem('restaurantCurrency') || undefined,
    onboarding: {
      user_completed: undefined,
      account_completed: undefined,
      subscriptionStatus: undefined,
      mandateUrl: undefined,
      subscription: undefined,
      billingStatus: undefined,
      accountBilling: {
        payment_methods: [],
        setup_intent_status: undefined,
      }
    },
    apiURL: import.meta.env.VITE_APP_SERVER_URL + 'dashboard/api/v1',
    isInitialized: false,
  }),

  getters: {
    isLoggedIn: (state) => !!state.token,
    isAdmin: (state) => !state.adminImpersonateRole && state.user.is_admin,
    isImpersonating: (state) => state.adminImpersonateRole != null,
    canManageAccounts: (state) => !state.adminImpersonateRole && (state.user.is_admin || state.user.is_moderator || state.user.is_reseller),
    canManageFinancial: (state) => {
      const people = ['dorus@orderli.com', 'maurits@orderli.com', 'mckay@orderli.com', 'henry@orderli.com']
      return people.includes(state.user.email)
    },
    canDoDeveloperActions: (state) => {
      const people = ['dorus@orderli.com', 'mckay@orderli.com', 'henry@orderli.com', 'tas@orderli.com']
      return people.includes(state.user.email)
    },
    currentRole: (state) => {
      const accountsStore = useAccountsStore();
      return state.adminImpersonateRole ? state.adminImpersonateRole : accountsStore.currentAccount?.role;
    },
    currentUser: (state) => state.user,
    userFullName: (state) => `${state.user.first_name} ${state.user.last_name}`,
    userFirstName: (state) => state.user.first_name,
    userLastName: (state) => state.user.last_name,
    userEmail: (state) => state.user.email,
    onboardingCompleted: (state) => {
      const canManageAccounts = !state.adminImpersonateRole && (state.user.is_admin || state.user.is_moderator || state.user.is_reseller);

      if (canManageAccounts && state.onboarding.user_completed !== false) return true;

      if (state.onboarding.user_completed === false || state.onboarding.account_completed === false) {
        return state.onboarding.user_completed && 
               state.onboarding.account_completed && 
               (state.accountId !== undefined && state.restaurantId !== undefined);
      } else if (state.onboarding.user_completed === undefined || state.onboarding.account_completed === undefined) {
        return undefined;
      } else {
        return state.onboarding.user_completed && 
               state.onboarding.account_completed && 
               (state.accountId !== undefined && state.restaurantId !== undefined);
      }
    },
  },

  actions: {
    async initialize() {
      if (this.isInitialized) return;
      
      try {
        // First check user status
        await this.checkUserStatus();
        
        // Then load accounts
        const accountsStore = useAccountsStore();
        await accountsStore.getAccounts();
        
        // Set initialization flag
        this.isInitialized = true;
        
        return true;
      } catch (error) {
        console.error('Failed to initialize auth store:', error);
        this.resetAuthState();
        throw error;
      }
    },

    async checkUserStatus() {
      if (!this.token) return null;
      
      try {
        const response = await GET('users');
        this.setUserObj(response.user);
        return response;
      } catch (error) {
        console.error('Error checking user status:', error);
        this.resetAuthState();
        throw error;
      }
    },

    async login(loginData) {
      this.status = 'loading'
      try {
        const response = await POST('users/login', loginData)
        this.status = 'success'
        this.loginDetails = loginData
        this.setJwt(response.token)
        await this.checkOnboardingStatus()
        return response
      } catch (error) {
        this.status = 'error'
        throw error
      }
    },

    async logout() {
      try {
        await POST('users/logout')
      } catch (error) {
        if (error.response?.status !== 401) {
          console.error('Logout error:', error)
        }
      } finally {
        this.resetAuthState()
        await router.push('/login')
      }
    },

    setJwt(token) {
      localStorage.setItem('jwt', token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      this.token = token
    },

    removeJwt() {
      localStorage.removeItem('jwt')
      delete axios.defaults.headers.common['Authorization']
      this.token = ''
    },

    resetAuthState() {
      this.$reset()
      this.removeJwt()
    },

    async checkOnboardingStatus() {
      try {
        await this.checkUserStatus()
        const accountsStore = useAccountsStore()
        const accountsResponse = await accountsStore.getAccounts()

        if (!accountsResponse.accounts || accountsResponse.accounts.length === 0) {
          if (this.canManageAccounts) {
            await router.push('/reseller')
            return
          }
        }

        if (!(this.accountId && accountsResponse.accounts.some(x => x.id === this.accountId))) {
          this.accountId = accountsResponse.accounts[0].id
          this.restaurantId = accountsResponse.accounts[0].restaurants[0]?.id
        } else if (!(this.restaurantId && accountsResponse.accounts.find(x => x.id === this.accountId)?.restaurants.some(x => x.id === this.restaurantId))) {
          this.restaurantId = accountsResponse.accounts.find(x => x.id === this.accountId).restaurants[0]?.id
        }

        const onboardingStatus = await this.getOnboardingStatus()
        this.onboarding.subscription = onboardingStatus.subscription_selected
        this.onboarding.user_completed = onboardingStatus.user_completed
        this.onboarding.account_completed = onboardingStatus.account_completed

        if (!onboardingStatus.account_completed) return

        const excludedAccountIds = [18, 92]
        if ((this.canManageAccounts || this.currentRole === 'owner') && !excludedAccountIds.includes(this.accountId)) {
          const billingStore = useBillingStore()
          await billingStore.getBilling()
        }
      } catch (error) {
        console.error('Error during onboarding status check:', error)
        this.onboarding.user_completed = false
        this.onboarding.account_completed = false
        this.resetAuthState()
        await router.push('/login')
      }
    },

    async getOnboardingStatus() {
      return await GET('onboarding/status')
    },

    setUserObj(userObj) {
      Object.assign(this.user, userObj)
      Sentry.setUser({ email: userObj.email })
    },

    setAdminImpersonateRole(value) {
      this.adminImpersonateRole = value
    },

    setAccount(accountId) {
      this.accountId = accountId
      localStorage.setItem('accountId', accountId)
    },

    setRestaurant(restaurantId) {
      this.restaurantId = restaurantId
      localStorage.setItem('restaurantId', restaurantId)
    },

    setRestaurantCurrency(currency) {
      this.restaurantCurrency = currency
      localStorage.setItem('restaurantCurrency', currency)
    },

    async register(registerData) {
      return await POST('users/signup', registerData)
    },

    async confirmEmail(token) {
      try {
        const response = await POST(`users/confirm/${token}`)
        this.setJwt(response.token)
        await this.checkOnboardingStatus()
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async completeUser(userDetails) {
      try {
        const response = await POST('onboarding/complete-user', userDetails)
        this.onboarding.user_completed = true
        return response
      } catch (error) {
        this.onboarding.user_completed = false
        console.error(error)
        throw error
      }
    },

    async completeAccount(accountDetails) {
      try {
        const response = await POST('onboarding/complete-account', accountDetails)
        this.onboarding.account_completed = true
        this.onboarding.mandateUrl = response.url
        accountDetails.id = this.accountId
        const accountsStore = useAccountsStore()
        accountsStore.updateAccountInStore(accountDetails)
        return response
      } catch (error) {
        this.onboarding.account_completed = false
        console.error(error)
        throw error
      }
    },

    async validateInvite(token) {
      return await POST(`users/validate-invite/${token}`)
    },

    async join(data) {
      try {
        const response = await POST(`users/join/${data.token}`, data.passwordData)
        this.setJwt(response.token)
        await this.checkOnboardingStatus()
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async forgotPassword(email) {
      return await POST('users/forget', email)
    },

    async validateReset(token) {
      return await POST(`users/validate-reset/${token}`)
    },

    async resetPassword(resetData) {
      try {
        const response = await POST(`users/reset/${resetData.token}`, resetData.data)
        this.setJwt(response.token)
        await this.checkOnboardingStatus()
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    async updateUserInformation(user) {
      try {
        const response = await PUT('users', user)
        this.setUserObj(user)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },

    userShouldHaveBeenLoggedOut() {
      if (this.loggedIn && !import.meta.env.VITE_APP_SKIP_ROUTING) {
        this.loggedIn = false
        this.removeJwt()
        router.push('/login')
      }
    },

    async getAccounts(isAdmin) {
      const accountsStore = useAccountsStore()
      return await accountsStore.getAccounts(isAdmin)
    },

    async getBilling() {
      const billingStore = useBillingStore()
      return await billingStore.getBilling()
    },

    setSubscriptionStatus(status) {
      this.onboarding.subscriptionStatus = status;
    },
  },
})

export const getAccountId = () => useAuthStore().accountId
export const getRestaurantId = () => useAuthStore().restaurantId