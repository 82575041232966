import { ref, computed, watch } from 'vue'
import { useScroll } from '@vueuse/core'

export function useStickyHeaders(navbarHeight: number, scrollContainer: HTMLElement) {
  const isNavbarVisible = ref(true)
  const lastScrollY = ref(0)
  const isHeaderSticky = ref(false)
  const scrollThreshold = 20 // Reduced threshold for more sensitivity
  const scrollDirection = ref('up')
  const lastToggleTime = ref(0)
  const toggleCooldown = 100 // Reduced cooldown for quicker response

  const { y: scrollY } = useScroll(scrollContainer)

  watch(scrollY, (newScrollY) => {
    const currentTime = Date.now()
    const scrollDifference = newScrollY - lastScrollY.value

    // Determine scroll direction
    if (scrollDifference > 0) {
      scrollDirection.value = 'down'
    } else if (scrollDifference < 0) {
      scrollDirection.value = 'up'
    }

    // Check if we've scrolled enough and if we're not in the cooldown period
    if (Math.abs(scrollDifference) > 0 && currentTime - lastToggleTime.value > toggleCooldown) {
      if (scrollDirection.value === 'down' && newScrollY > navbarHeight) {
        if (isNavbarVisible.value) {
          isNavbarVisible.value = false
          lastToggleTime.value = currentTime
        }
      } else if (scrollDirection.value === 'up') {
        if (!isNavbarVisible.value) {
          isNavbarVisible.value = true
          lastToggleTime.value = currentTime
        }
      }
    }

    lastScrollY.value = newScrollY
  })

  const headerTop = computed(() => (isNavbarVisible.value ? navbarHeight : 0))

  return {
    isNavbarVisible,
    headerTop,
    scrollY,
  }
}