import { useAuthStore } from '@/stores/auth'

/**
 * Check for accountId and restaurantId in query params and set them to the Pinia store
 */
export function getIdsFromQueryParams(URLSearchParams) {
  const authStore = useAuthStore()
  let query = {}
  let hasIds =
    URLSearchParams.has('accountId') && URLSearchParams.has('restaurantId')

  if (hasIds) {
    authStore.setAccount(parseInt(URLSearchParams.get('accountId')))
    authStore.setRestaurant(parseInt(URLSearchParams.get('restaurantId')))
    URLSearchParams.delete('accountId')
    URLSearchParams.delete('restaurantId')

    //Make a new object for the params, each 'entry' is a [key, value] tupple
    for (const [key, value] of URLSearchParams.entries()) {
      query[key] = value
    }
  }

  return { hasIds, query }
}
