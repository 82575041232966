import axios from 'axios';
import { getAccountId, getRestaurantId } from '../stores/auth';

const DEFAULT_BASE_URL = 'dashboard/api/v1';

const replaceUrlParams = (url) => {
  return url.replace(':accountId', getAccountId()).replace(':restaurantId', getRestaurantId());
};

const createApiMethod = (method) => async (url, data = {}, config = {}) => {
  const { base_url, ...restConfig } = config;
  const fullUrl = `${base_url || DEFAULT_BASE_URL}/${replaceUrlParams(url)}`;

  try {
    const response = await axios[method](fullUrl, method === 'get' ? { params: data } : data, restConfig);
    return response.data;
  } catch (error) {
    console.error(`${method.toUpperCase()} error:`, error);
    throw error;
  }
};

export const GET = createApiMethod('get');
export const POST = createApiMethod('post');
export const PUT = createApiMethod('put');
export const DELETE = createApiMethod('delete');
