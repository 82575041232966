<template>
  <router-view></router-view>
  <Toaster class="z-[2147483551]" />
</template>

<script>
import { defineComponent } from 'vue'
import { Toaster } from 'vue-sonner'

export default defineComponent({
  components: {
    Toaster
  }
})
</script>