<template>
  <Transition name="slide-y-up" :duration="animationDuration">
    <div
      v-if="show"
      class="modal fade"
      @mousedown.self="closeFromBackdrop"
      :class="[
        'show block',
        { 'modal-mini': type === 'mini' },
      ]"
      tabindex="-1"
      role="dialog"
      :aria-hidden="!show"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="[
          { 'modal-notice': type === 'notice', [`modal-${size}`]: size },
          modalClasses,
        ]"
      >
        <div
          class="modal-content"
          :class="[
            gradient ? `bg-gradient-${gradient}` : '',
            modalContentClasses,
          ]"
        >
          <div class="headerWarning" v-if="$slots.headerWarning">
            <slot name="headerWarning"></slot>
          </div>
          <div
            class="modal-header"
            :class="[headerClasses]"
            v-if="$slots.header"
          >
            <slot name="header"></slot>
            <slot name="close-button">
              <button
                v-if="showClose"
                type="button"
                class="close"
                @click="closeModal"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span :aria-hidden="!show">×</span>
              </button>
            </slot>
          </div>

          <div class="modal-body" :class="bodyClasses">
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue'

const props = defineProps({
  show: Boolean,
  closeWarning: Boolean,
  noCloseOnBackdrop: { type: Boolean, default: false },
  dontCloseBackdropOnMobile: { type: Boolean, default: true },
  showClose: {
    type: Boolean,
    default: true,
  },
  type: {
    type: String,
    default: '',
    validator(value) {
      let acceptedValues = ['', 'notice', 'mini']
      return acceptedValues.indexOf(value) !== -1
    },
  },
  modalClasses: {
    type: [Object, String],
  },
  size: {
    type: String,
    validator(value) {
      let acceptedValues = ['', 'sm', 'lg', 'xl']
      return acceptedValues.indexOf(value) !== -1
    },
  },
  modalContentClasses: {
    type: [Object, String],
  },
  gradient: {
    type: String,
  },
  headerClasses: {
    type: [Object, String],
  },
  bodyClasses: {
    type: [Object, String],
  },
  footerClasses: {
    type: [Object, String],
  },
  animationDuration: {
    type: Number,
    default: 300,
  },
})

const emit = defineEmits(['update:show', 'close'])

const closeModal = () => {
  emit('update:show', false)
  emit('close')
}

const closeFromBackdrop = () => {
  if (props.noCloseOnBackdrop) return
  if (props.dontCloseBackdropOnMobile) {
    if (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    ) {
      return
    }
  }
  closeModal()
}

watch(() => props.show, (val) => {
  let documentClasses = document.body.classList
  if (val) {
    documentClasses.add('modal-open')
    nextTick(() => {
      const autofocusElement = document.querySelector('[ref="autofocus"]')
      if (autofocusElement) {
        autofocusElement.focus()
      }
    })
  } else {
    documentClasses.remove('modal-open')
  }
})
</script>

<style>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>

<style scoped>
.headerWarning {
  width: 100%;
  background: red;
  height: 30px;
  line-height: 30px;
  border-radius: 0.4375rem 0.4375rem 0 0;
  text-align: center;
  color: white;
}

.slide-y-up-enter-active,
.slide-y-up-leave-active {
  transition: all v-bind(animationDuration + 'ms') ease;
}
.slide-y-up-enter-from,
.slide-y-up-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
</style>
