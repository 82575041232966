import { ref, h, render } from 'vue'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { useClipboard } from '@vueuse/core'
import { toast } from 'vue-sonner'

interface RawObjectDialogOptions {
  title?: string
  object: unknown
}

export function useRawObjectDialog() {
  const isOpen = ref(false)
  let resolvePromise: ((value: boolean) => void) | null = null
  let container: HTMLDivElement | null = null
  const { copy } = useClipboard()

  const showRawObject = async (options: RawObjectDialogOptions): Promise<void> => {
    const { title = 'Raw Object Data', object } = options

    // Clean up any existing dialog
    if (container) {
      document.body.removeChild(container)
    }

    container = document.createElement('div')
    document.body.appendChild(container)

    isOpen.value = true

    const formattedObject = JSON.stringify(object, null, 2)

    const copyRawData = () => {
      copy(formattedObject)
      toast.success('Raw data copied to clipboard')
    }

    const dialogComponent = h(Dialog, {
      open: isOpen.value,
      'onUpdate:open': (open: boolean) => {
        isOpen.value = open
        if (!open) {
          cleanup()
        }
      }
    }, {
      default: () => h(DialogContent, {
        class: 'w-full max-w-[90vw] h-[90vh] flex flex-col',
        autofocus: false
      }, {
        default: () => [
          h(DialogHeader, { class: 'flex flex-row items-center justify-between' }, {
            default: () => [
              h('div', { class: 'flex items-center space-x-4' }, [
                h(DialogTitle, null, () => title),
                h(Button, {
                  variant: 'outline',
                  size: 'sm',
                  onClick: copyRawData,
                  // Add tabindex to prevent focus
                  tabindex: '-1'
                }, () => 'Copy Raw Data')
              ])
            ]
          }),
          h(DialogDescription, null, () => ''),
          h('div', {
            class: 'flex-1 overflow-auto'
          }, [
            h('pre', {
              class: 'text-sm p-4 bg-muted rounded-md',
              style: 'white-space: pre-wrap; word-wrap: break-word;'
            }, formattedObject)
          ])
        ]
      })
    })

    render(dialogComponent, container)

    return new Promise<void>((resolve) => {
      resolvePromise = () => {
        resolve()
      }
    })
  }

  const cleanup = () => {
    isOpen.value = false
    if (container) {
      render(null, container)
      document.body.removeChild(container)
      container = null
    }
    if (resolvePromise) {
      resolvePromise(true)
      resolvePromise = null
    }
  }

  return {
    showRawObject
  }
}
