import { defineStore } from 'pinia'
import { GET, POST, PUT, DELETE } from '../utils/api'
import { useAuthStore } from './auth'
import { useAccountsStore } from './accounts'
import { useBillingStore } from './billing'
import { nextTick } from 'vue'

export const useRestaurantsStore = defineStore('restaurants', {
  state: () => ({
    api_tokens: [],
    logoURL: import.meta.env.VITE_APP_SERVER_URL + 'logo/',
  }),

  getters: {
    allRestaurants() {
      const accountsStore = useAccountsStore()
      return accountsStore.accounts.flatMap(account => 
        account.restaurants.map(restaurant => ({
          ...restaurant,
          accountName: account.name,
          accountId: account.id,
          accountSubscription: account.subscription,
          new_billing: account.new_billing
        }))
      )
    },
    allRestaurantsMap() {
      return new Map(this.allRestaurants.map(element => [element.id, element]))
    },
    currentRestaurantId() {
      const authStore = useAuthStore()
      return authStore.restaurantId
    },
    currentRestaurantCurrency() {
      const authStore = useAuthStore()
      return authStore.restaurantCurrency
    },
    currentRestaurant() {
      const authStore = useAuthStore()
      const accountsStore = useAccountsStore()
      
      console.log('🔍 [Restaurants Store] Checking current restaurant...')
      console.log('📌 currentAccountId:', authStore.accountId)
      console.log('🔑 currentRestaurantId:', this.currentRestaurantId)
      console.log('📂 currentAccount:', accountsStore.currentAccount)

      const restaurant = accountsStore.currentAccount?.restaurants.find(y => y.id === this.currentRestaurantId)
      
      if (!restaurant) {
        console.warn(`⚠️ [Restaurants Store] Restaurant with ID ${this.currentRestaurantId} not found in current account.`)
        console.log('🔍 Available restaurants in current account:', accountsStore.currentAccount?.restaurants.map(r => r.id))
        return null
      }

      if (restaurant.name && (accountsStore.accounts.length > 1 || accountsStore.currentAccount.restaurants.length > 1)) {
        document.title = `My Orderli - ${restaurant.name}`
      } else {
        document.title = 'My Orderli'
      }
      return restaurant
    },
  },

  actions: {
    async clearRestaurantCache() {
      await POST(`accounts/:accountId/clear-restaurant-cache`)
      const accountsStore = useAccountsStore()
      await accountsStore.clearAccountCache()
      await accountsStore.getAccounts(false)
      alert('✅ cleared cache & reload successful, all account data should be up-to-date now')
    },

    async createRestaurant(restaurantData) {
      const response = await POST(`accounts/:accountId/restaurants`, restaurantData)
      this.setRestaurant(response.restaurant.id)
      const accountsStore = useAccountsStore()
      accountsStore.addRestaurantToAccount({
        accountId: accountsStore.currentAccountId,
        restaurantToAdd: response.restaurant
      })
      await accountsStore.clearAccountCache()
      return response
    },

    async deleteRestaurant(restaurantId) {
      const response = await DELETE(`accounts/:accountId/restaurants/${restaurantId}`)
      const accountsStore = useAccountsStore()
      accountsStore.removeRestaurantFromAccount({accountId: accountsStore.currentAccountId, restaurantToRemove: restaurantId})
      await accountsStore.clearAccountCache()
      return response
    },

    async getRestaurant() {
      return await GET(`accounts/:accountId/restaurants/:restaurantId`)
    },

    async updateRestaurant(restaurant) {
      try {
        const response = await PUT(`accounts/:accountId/restaurants/${restaurant.id}`, restaurant)
        this.updateRestaurantInStore({restaurant: restaurant, accountId: this.currentAccountId})
        return response
      } catch (err) {
        console.error(err)
        // TODO: Replace Vue.prototype.$swal with appropriate error handling in Vue 3
        throw err
      }
    },

    async getApiTokens() {
      const response = await GET(`accounts/:accountId/restaurants/:restaurantId/api-tokens`)
      this.api_tokens = response.tokens
      return response
    },

    async createApiToken(token) {
      const response = await POST(`accounts/:accountId/restaurants/:restaurantId/api-tokens`, token)
      this.api_tokens.push(response.token)
      return response
    },

    async updateApiToken(token) {
      const response = await PUT(`accounts/:accountId/restaurants/:restaurantId/api-tokens/${token.id}`, token)
      const index = this.api_tokens.findIndex(t => t.id === token.id)
      if (index !== -1) {
        this.api_tokens[index] = token
      }
      return response
    },

    async deleteApiToken(tokenId) {
      const response = await DELETE(`accounts/:accountId/restaurants/:restaurantId/api-tokens/${tokenId}`)
      this.api_tokens = this.api_tokens.filter(token => token.id !== tokenId)
      return response
    },

    setRestaurant(id) {
      const authStore = useAuthStore()
      localStorage.setItem('restaurantId', id)
      authStore.restaurantId = id
      this.setRestaurantCurrency()
    },

    // TODO: Remove this once we've confirmed we migrated to the new billing store correctly
    // setRestaurantBilling(data) {
    //   const billingStore = useBillingStore()
    //   if (data.hasOwnProperty('restaurantId')) {
    //     billingStore.restaurantBilling[data.restaurantId] = data.billing
    //   } else {
    //     billingStore.restaurantBilling = data
    //   }
    // },

    setRestaurantWithReload(id) {
      const authStore = useAuthStore()
      localStorage.setItem('restaurantId', id)
      authStore.restaurantId = id
      this.setRestaurantCurrency(false)
      nextTick(() => {
        window.location.reload() // Quick fix to reload and reset all state related settings, TODO do this better
      })
    },

    setRestaurantCurrency(updateState = true) {
      const authStore = useAuthStore()
      let currency = this.currentRestaurant?.currency || 'EUR'
      localStorage.setItem('restaurantCurrency', currency)
      if (updateState) {
        authStore.restaurantCurrency = currency
      }
    },

    updateRestaurantInStore(data) {
      const accountsStore = useAccountsStore()
      let account = accountsStore.accounts.find(x => x.id === data.accountId)
      let foundIndex = account.restaurants.findIndex(x => x.id === data.restaurant.id)

      let updatedRestaurant = { ...account.restaurants[foundIndex] }
      Object.keys(data.restaurant).forEach(function (prop) {
        updatedRestaurant[prop] = JSON.parse(JSON.stringify(data.restaurant[prop]))
      })

      account.restaurants[foundIndex] = updatedRestaurant // refresh Vue reactivity on restaurant change
    },
  },
})
