<template>
  <div>
    <div
      class="navbar navbar-horizontal navbar-main navbar-transparent navbar-expand-lg"
    >
      <div class="container items-center justify-content-xl-center">
        <a href="https://www.orderli.com" class="font-bold ml-1">
          <img style="height: 30px" src="/img/brand/orderli-white-mono.png" alt="Orderli logo" />
        </a>
      </div>
    </div>

    <div class="main-content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>

    <footer class="py-5" id="footer-main">
      <div class="container">
        <div class="row items-center justify-content-xl-center">
          <div class="col-xl-6">
            <div class="copyright text-center text-xl-center text-gray">
              © {{ year }} Orderli
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount, watch } from 'vue'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'

// Props
const props = defineProps({
  backgroundColor: {
    type: String,
    default: 'black',
  },
})

// Data
const showMenu = ref(false)
const menuTransitionDuration = 250
const pageTransitionDuration = 200
const year = new Date().getFullYear()
const pageClass = ref('login-page')

// Computed
const route = useRoute()
const title = computed(() => `${route.name} Page`)

// Methods
const toggleNavbar = () => {
  document.body.classList.toggle('nav-open')
  showMenu.value = !showMenu.value
}

const closeMenu = () => {
  document.body.classList.remove('nav-open')
  showMenu.value = false
}

const setBackgroundColor = () => {
  // document.body.classList.add('bg-default');
}

const removeBackgroundColor = () => {
  document.body.classList.remove('bg-default')
}

const updateBackground = () => {
  if (!route.meta.noBodyBackground) {
    setBackgroundColor()
  } else {
    removeBackgroundColor()
  }
}

// Lifecycle hooks
onBeforeUnmount(() => {
  removeBackgroundColor()
})

onBeforeRouteUpdate((to, from, next) => {
  // Close the mobile menu first then transition to next page
  if (showMenu.value) {
    closeMenu()
    setTimeout(() => {
      next()
    }, menuTransitionDuration)
  } else {
    next()
  }
})

// Watch
watch(() => route, () => {
  updateBackground()
}, { deep: true, immediate: true })

</script>

<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.main-content .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
