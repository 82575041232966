<template>
  <footer class="p-6 mt-8 mx-2">
    <div class="flex items-center justify-between lg:justify-between">
      <div class="w-full lg:w-1/2">
        <div class="text-center lg:text-left text-muted-foreground">
          © {{ year }} Orderli
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { ref } from 'vue'

const year = ref(new Date().getFullYear())
</script>
