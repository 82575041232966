<template>
  <Popover v-model:open="open">
    <PopoverTrigger asChild>
      <Button variant="ghost" size="icon" class="relative" :class="{ 'bg-accent': open }">
        <BellIcon class="h-4 w-4" />
        <span v-if="notificationArray.length > 0" class="absolute top-1 right-1 -mt-1 -mr-1 h-3 w-3 rounded-full bg-red-500 text-[8px] font-medium text-white flex items-center justify-center">
          {{ notificationArray.length }}
        </span>
      </Button>
    </PopoverTrigger>
    <PopoverContent class="w-80" align="end">
      <div class="flex justify-between items-center mb-2">
        <h5 class="text-sm font-medium">Notifications</h5>
        <Button v-if="isAdmin && notificationArray.length" variant="ghost" size="sm" @click="clearNotifications">
          Clear all
        </Button>
      </div>
      <ScrollArea class="h-[300px]">
        <div v-if="notificationArray.length === 0" class="text-center py-4 text-gray-500">
          No notifications
        </div>
        <div v-else>
          <NotificationCard
            v-for="notification in notificationArray"
            :key="notification.title"
            :card-title="notification.title"
            :card-description="notification.description"
            :card-link="notification.link"
          />
        </div>
      </ScrollArea>
    </PopoverContent>
  </Popover>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useBillingStore } from '@/stores/billing'
import { useAccountsStore } from '@/stores/accounts'
import { useRestaurantsStore } from '@/stores/restaurants'
import { useRouter } from 'vue-router'
import { BellIcon } from 'lucide-vue-next'
import { Button } from '@/components/ui/button'
import { Popover, PopoverTrigger, PopoverContent } from '@/components/ui/popover'
import { ScrollArea } from '@/components/ui/scroll-area'
import NotificationCard from '@/components/NotificationBell/NotificationCard.vue'

const authStore = useAuthStore()
const billingStore = useBillingStore()
const accountsStore = useAccountsStore()
const restaurantsStore = useRestaurantsStore()
const router = useRouter()

const isAdmin = computed(() => authStore.isAdmin)
const currentRestaurant = computed(() => restaurantsStore.currentRestaurant)
const trialEnded = computed(() => {
  const trialDate = new Date(currentRestaurant.value?.trial_ends_at)
  const today = new Date()
  return trialDate < today
})

const addressEntered = computed(() => {
  if (currentRestaurant.value && !currentRestaurant.value.address) {
    return {
      title: 'Address missing',
      description: 'Please enter the address of your restaurant in settings.',
      link: '/settings',
    }
  }
  return undefined
})

const logoUploaded = computed(() => {
  if (currentRestaurant.value && currentRestaurant.value.logo === null) {
    return {
      title: 'Logo not uploaded',
      description: 'Please upload a logo in settings.',
      link: '/settings#billing',
    }
  }
  return undefined
})

const mandateGiven = computed(() => {
  if (billingStore.mandateGiven === false) {
    return {
      title: 'Mandate not completed',
      description: 'Please configure the mandate in billing settings.',
      link: '/settings#billing',
    }
  }
  return undefined
})

const moreLanguagesEnabled = computed(() => {
  if (currentRestaurant.value && currentRestaurant.value.languages.length === 0) {
    return {
      title: 'No menu language selected',
      description: 'Please select a language in settings.',
      link: '/settings',
    }
  }
  return undefined
})

const pspLinked = computed(() => {
  if (
    currentRestaurant.value &&
    currentRestaurant.value.psp_integration &&
    currentRestaurant.value.psp_integration !== 'payaut' &&
    currentRestaurant.value.psp_api_key === null
  ) {
    return {
      title: 'PSP not linked',
      description: 'Please link the payment service provider in payment settings.',
      link: '/settings#payments',
    }
  }
  return undefined
})

const subscriptionSelected = computed(() => {
  if (accountsStore.currentAccount && !accountsStore.currentAccount.subscription) {
    return {
      title: 'Subscription not selected',
      description: 'Please select a subscription in billing settings.',
      link: '/settings#billing',
    }
  }
  return undefined
})

const vatNumberEntered = computed(() => {
  if (currentRestaurant.value && !currentRestaurant.value.vat_number) {
    return {
      title: 'VAT Number missing',
      description: 'Please enter a VAT number in settings.',
      link: '/settings',
    }
  }
  return undefined
})

const notificationArray = computed(() => {
  return [
    addressEntered.value,
    logoUploaded.value,
    mandateGiven.value,
    moreLanguagesEnabled.value,
    pspLinked.value,
    subscriptionSelected.value,
    vatNumberEntered.value,
  ].filter(Boolean)
})

const open = ref(false)

function clearNotifications() {
  // Implement the logic to clear notifications
  // This could be a Pinia action or a method to update the store
  console.log('Clearing notifications')
}
</script>
