import { throttle } from 'throttle-debounce'

export default {
  inserted: function (el, binding) {
    const onScrollCallback = binding.value
    const throttledScrollHandler = throttle(300, () => {
      const y = window.scrollY
      onScrollCallback(y)
    })
    document.addEventListener('scroll', throttledScrollHandler)
  },
}
