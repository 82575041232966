// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills'
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin'
// Validation plugin used to validate forms
import { configure, defineRule } from 'vee-validate'
import * as AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'
import en from '@vee-validate/i18n/dist/locale/en.json'
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives'
// A plugin file where you could register global filters
import GlobalFilters from './globalFilters'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin'

// asset imports
import '@/assets/sass/argon.scss'
import '@/assets/css/nucleo/css/nucleo.css'

// Register all rules from VeeValidate
Object.entries(AllRules).forEach(([ruleName, ruleFunction]) => {
  if (typeof ruleFunction === 'function') {
    defineRule(ruleName, ruleFunction);
  }
});

// Localize to English
localize({ en });

export default {
  install(app) {
    app.use(GlobalComponents)
    app.use(GlobalDirectives)
    app.use(GlobalFilters)
    app.use(SideBar)
    app.use(Notifications)
    configure({
      generateMessage: localize('en'),
      validateOnInput: true,
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      },
    })
  },
}
