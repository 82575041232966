<script setup lang="ts">
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'

const props = defineProps<{
  class?: HTMLAttributes['class']
  name?: string
}>()

// Expose the tbody element to parent components
defineExpose({
  tbody: null as HTMLTableSectionElement | null
})

const beforeLeave = (el: Element) => {
  if (el instanceof HTMLTableRowElement) {
    el.dataset.leaving = 'true'
  }
}
</script>

<template>
  <transition-group 
    v-bind="$attrs"
    :class="cn('[&_tr:last-child]:border-0', props.class)"
    tag="tbody"
    name="table"
    @before-leave="beforeLeave"
  >
    <slot />
  </transition-group>
</template>
