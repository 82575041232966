<template>
  <div v-if="hasAccessToFeature">
    <slot></slot>
  </div>
  <div v-else-if="suggestedSubscription">
    <div
      class="locked-container alert mb-0 alert-light"
      role="alert"
      :style="{ height: containerHeight, width: containerWidth }"
    >
      <h4 class="text-white">
        <i class="fa-solid fa-lock mr-2"></i>
        This feature requires
        <strong>{{ suggestedSubscription?.name }}</strong>
      </h4>
      <div>
        Click
        <a
          v-if="openInvoices.length === 0"
          class="hyperlink"
          @click="showChangeSubscriptionModal = true"
          >here</a
        >
        <el-tooltip
          v-else
          content="Make sure all open invoices are paid"
          placement="bottom"
          data-toggle="tooltip"
        >
          <a class="hyperlink">here</a>
        </el-tooltip>
        to upgrade your plan, or contact us for a trial.
      </div>
    </div>

    <Modal size="lg" v-model:show="showChangeSubscriptionModal">
      <template #header>
        <h6 class="modal-title">Upgrade subscription</h6>
      </template>
      <SubscriptionFeatureUpgradeCards
        :currentSubscription="currentSubscription"
        :suggestedSubscription="suggestedSubscription"
        v-model:value="selectedSubscription"
      />
      <template #footer>
        <BaseButton
          type="secondary"
          @click="showChangeSubscriptionModal = false"
          >Close</BaseButton
        >
        <BaseButton
          type="primary"
          :disabled="!suggestedSubscription"
          @click="updateSubscription"
          >Upgrade</BaseButton
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useAccountsStore } from '@/stores/accounts'
import { useBillingStore } from '@/stores/billing'
import SubscriptionFeatureUpgradeCards from '@/components/Inputs/SubscriptionFeatureUpgradeCards.vue'
import Modal from '@/components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import { ElTooltip } from 'element-plus'

export default defineComponent({
  name: 'UnlockFeature',

  props: {
    feature: String,
    height: String,
    width: String,
  },

  components: {
    SubscriptionFeatureUpgradeCards,
    Modal,
    BaseButton,
    ElTooltip,
  },

  setup(props) {
    const authStore = useAuthStore()
    const accountsStore = useAccountsStore()
    const billingStore = useBillingStore()

    const showChangeSubscriptionModal = ref(false)
    const selectedSubscription = ref(null)

    const containerHeight = computed(() => props.height || 'auto')
    const containerWidth = computed(() => props.width || 'auto')

    const isAdmin = computed(() => authStore.isAdmin)
    const allInvoices = computed(() => billingStore.accountInvoices)
    const openInvoices = computed(() =>
      allInvoices.value.filter(
        (invoice) => invoice.status !== 'paid' && invoice.status !== 'void'
      )
    )
    const currentAccount = computed(() => accountsStore.currentAccount)
    const currentSubscription = computed(() => {
      selectedSubscription.value = currentAccount.value.subscription?.database_name
      return currentAccount.value.subscription
    })
    const subscriptions = computed(() => billingStore.subscriptions)
    const subscriptionOptions = computed(() => {
      let allSubscriptions = [...subscriptions.value]
      if (
        currentSubscription.value &&
        !allSubscriptions.some(
          (x) => x.database_name === currentSubscription.value?.database_name
        )
      ) {
        allSubscriptions.push(currentSubscription.value)
      }
      return allSubscriptions
    })

    const hasAccessToFeature = computed(() =>
      currentAccount.value &&
      currentSubscription.value &&
      (isAdmin.value ||
        currentSubscription.value.features.includes(props.feature))
    )

    const suggestedSubscription = computed(() => {
      let closestSubscription = null
      if (
        !currentAccount.value ||
        !subscriptions.value ||
        !currentSubscription.value ||
        hasAccessToFeature.value
      ) {
        return null
      }
      let allSubscriptions = [...subscriptions.value]
      allSubscriptions
        .sort(compareSubscriptions)
        .forEach((subscription) => {
          if (
            closestSubscription === null &&
            subscription.features.includes(props.feature)
          ) {
            closestSubscription = subscription
          }
        })
      return closestSubscription
    })

    function updateSubscription() {
      if (!suggestedSubscription.value) {
        return
      }
      billingStore
        .updateBilling(suggestedSubscription.value)
        .then(() => {
          showChangeSubscriptionModal.value = false
        })
        .catch((err) => {
          alert(err.response?.data.message || err)
        })
    }

    function compareSubscriptions(a, b) {
      // Implement your comparison logic here
      return 0
    }

    if (!subscriptions.value.length) {
      billingStore.getSubscriptions()
    }

    return {
      showChangeSubscriptionModal,
      selectedSubscription,
      containerHeight,
      containerWidth,
      isAdmin,
      openInvoices,
      currentAccount,
      currentSubscription,
      subscriptions,
      subscriptionOptions,
      hasAccessToFeature,
      suggestedSubscription,
      updateSubscription,
    }
  },
})
</script>

<style scoped>
a.hyperlink {
  color: #d64f28 !important;
  text-decoration: none;
  cursor: pointer;
}
a.hyperlink:hover {
  text-decoration: underline;
}
</style>