<template>
  <Dialog v-if="user" v-model:open="isOpen">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Editing Profile</DialogTitle>
      </DialogHeader>
      <form @submit.prevent="onSubmit">
        <div class="space-y-4">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <Label for="first-name">First Name</Label>
              <Input id="first-name" v-model="user.first_name" />
            </div>
            <div>
              <Label for="last-name">Last Name</Label>
              <Input id="last-name" v-model="user.last_name" />
            </div>
            <div>
              <Label for="phone-number">Phone Number</Label>
              <Input id="phone-number" v-model="user.phone_number" />
            </div>
            <div>
              <Label for="email">Email</Label>
              <Input id="email" v-model.trim="user.email" disabled />
            </div>
          </div>
        </div>
      </form>
      <DialogFooter>
        <Button @click="onClose" variant="outline">
          {{ changesMade ? 'Cancel' : 'Close' }}
        </Button>
        <Button @click="onSubmit" variant="default">Save changes</Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

const props = defineProps(['show'])
const emit = defineEmits(['update:show', 'modalClose'])

const authStore = useAuthStore()
const user = ref(undefined)
const watchUserChange = ref(false)
const changesMade = ref(false)
const isOpen = ref(props.show)

watch(() => props.show, (newVal) => {
  isOpen.value = newVal
})

watch(() => isOpen.value, (newVal) => {
  emit('update:show', newVal)
  if (!newVal) {
    emit('modalClose')
  }
})

nextTick(() => {
  watchUserChange.value = true
  user.value = JSON.parse(JSON.stringify(authStore.user))
})

watch(
  () => user.value,
  () => {
    if (watchUserChange.value) {
      changesMade.value = true
    }
  },
  { deep: true }
)

const onSubmit = () => {
  authStore.updateUserInformation(user.value)
    .then(() => {
      onClose()
    })
    .catch((err) => {
      alert(err)
    })
}

const onClose = () => {
  changesMade.value = false
  user.value = JSON.parse(JSON.stringify(authStore.user))
  isOpen.value = false
}
</script>
