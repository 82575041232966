<template>
  <div>
    <!-- Header -->
    <div
      class="header py-7 py-lg-8 pt-lg-9"
      style="
        background: linear-gradient(
          87deg,
          #d65729 0px,
          #d64629 100%
        ) !important;
      "
    >
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Ooops!</h1>
              <p class="text-lead text-white">
                Page not found. Don't worry though, we have plenty of other
                pages to explore
              </p>

              <router-link
                to="/dashboard"
                class="font-bold text-white mt-5"
                >Back to dashboard</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon fill="#f8f9fe" points="3800 0 3800 100 0 100"></polygon>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'not-found',
})
</script>
