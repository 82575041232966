<template>
  <div v-if="isAdmin">
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useAuthStore } from '@/stores/auth'

export default defineComponent({
  setup() {
    const authStore = useAuthStore()
    return { authStore }
  },
  name: 'IsAdmin',

  computed: {
    isAdmin() { return this.authStore.isAdmin },
  },
})
</script>
