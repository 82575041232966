import {
  restaurantCurrency,
  restaurantCurrencyCompact,
} from '@/filters/restaurantCurrency'
import {
  dateString,
  localDateString,
  localDateTimeString,
  dateTimeString,
  daysBetween,
  addDays,
} from '@/filters/dateTime'
import swal from 'sweetalert2'

/**
 * You can register global filters here and use them as a plugin in your main Vue instance
 */

const GlobalFilters = {
  install(app) {
    app.config.globalProperties.$filters = {
      restaurantCurrency,
      restaurantCurrencyCompact,
      date: dateString,
      localDate: localDateString,
      localDateTime: localDateTimeString,
      dateTime: dateTimeString,
    }

    app.config.globalProperties.restaurantCurrency = restaurantCurrency
    app.config.globalProperties.restaurantCurrencyCompact = restaurantCurrencyCompact
    app.config.globalProperties.dateString = dateString
    app.config.globalProperties.localDateString = localDateString
    app.config.globalProperties.localDateTimeString = localDateTimeString
    app.config.globalProperties.dateTimeString = dateTimeString
    app.config.globalProperties.daysBetween = daysBetween
    app.config.globalProperties.addDays = addDays
    app.config.globalProperties.$swal = swal
  },
}

export default GlobalFilters
