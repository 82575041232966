<template>
  <aside
    v-bind="$attrs"
    :class="[
      'fixed inset-y-0 left-0 z-50 bg-background border-r box-content transition-all duration-300 ease-in-out',
      { '-translate-x-full': !isOpen && isMobile },
      { 'w-52': !isMinimized && !isHovered },
      { 'w-52': !isMinimized || isHovered },
      { 'w-16': isMinimized && !isHovered }
    ]"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="flex flex-col h-full overflow-hidden">
      <div class="px-2 flex items-center min-h-16 relative">
        <Transition name="fade">
          <div v-show="!isMinimized || isHovered" class="w-full">
            <LocationSelector />
          </div>
        </Transition>
        <Transition name="fade">
          <div v-show="isMinimized && !isHovered" class="w-9 h-9 ml-1.5 border border-input bg-background shadow-sm box-border transition-opacity duration-1000 ease-in-out">
            <img 
              v-if="selectedLocation?.logo"
              :src="`https://imagedelivery.net/xS_5nksgKmcoB2_mcBGUmA/${selectedLocation.logo}/public`"
              class="w-full h-full rounded-sm object-contain"
              :alt="selectedLocation.name"
            />
          </div>
        </Transition>
      </div>
      <ScrollArea class="flex-grow">
        <nav class="space-y-1 pt-2 px-2">
          <slot :is-hovered="isHovered" :is-minimized="isMinimized"></slot>
        </nav>
      </ScrollArea>
      <div class="p-4 flex justify-end" v-if="!isMobile">
        <Button variant="ghost" size="icon" @click="toggleMinimize">
          <ChevronLeft v-if="!isMinimized || isHovered" />
          <ChevronRight v-if="isMinimized && !isHovered" />
        </Button>
      </div>
    </div>
  </aside>
  <div
    v-if="isOpen && isMobile"
    class="fixed inset-0 bg-black bg-opacity-50 z-40"
    @click="closeSidebar"
  ></div>
</template>

<script setup>
import { ref, computed, onMounted, watch, useAttrs } from 'vue'
import { useWindowSize } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ChevronLeft, ChevronRight } from 'lucide-vue-next'
import LocationSelector from '@/components/LocationSelector.vue'
import { useAuthStore } from '@/stores/auth'
import { useAccountsStore } from '@/stores/accounts'

const authStore = useAuthStore()
const accountsStore = useAccountsStore()

const props = defineProps({
  logo: {
    type: String,
    default: '/img/brand/orderli-orange.png'
  },
  isOpen: {
    type: Boolean,
    default: true
  },
  isMinimized: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:isOpen', 'update:isMinimized'])

const { width } = useWindowSize()
const isHovered = ref(false)
const isMobile = computed(() => width.value < 768)

const selectedLocation = computed(() => {
  const accountId = authStore.accountId
  const restaurantId = authStore.restaurantId
  const account = accountsStore.accounts.find(acc => acc.id === accountId)
  if (!account) return null
  const restaurant = account.restaurants.find(res => res.id === restaurantId)
  if (!restaurant) return { name: account.name, logo: null }
  return { name: restaurant.name, logo: restaurant.logo }
})

const toggleMinimize = () => {
  emit('update:isMinimized', !props.isMinimized)
  if (!props.isMinimized) {
    // If we're minimizing, immediately "unhover"
    isHovered.value = false
  }
}

const closeSidebar = () => {
  if (isMobile.value) {
    emit('update:isOpen', false)
  }
}

const onMouseEnter = () => {
  isHovered.value = true
}

const onMouseLeave = () => {
  isHovered.value = false
}

onMounted(() => {
  if (isMobile.value) {
    emit('update:isOpen', false)
  }
})

watch(isMobile, (newValue) => {
  if (newValue) {
    emit('update:isOpen', false)
    emit('update:isMinimized', false)
  } else {
    emit('update:isOpen', true)
  }
})

// Add this line to enable attribute inheritance
const attrs = useAttrs()
</script>

<style scoped>
.overflow-hidden {
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
