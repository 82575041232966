<template>
  <ContextMenu v-if="isAdmin">
    <ContextMenuTrigger asChild>
      <slot />
    </ContextMenuTrigger>
    
    <ContextMenuContent>
      <ContextMenuItem v-if="showRawData" @click="showRawObjectData">
        Show Raw Data
      </ContextMenuItem>
      
      <ContextMenuItem v-if="showCopyId && data?.id" @click="copyId">
        Copy ID
        <ContextMenuShortcut class="text-xs text-muted-foreground">
          {{ data.id }}
        </ContextMenuShortcut>
      </ContextMenuItem>
      
      <slot name="additional-items" />
    </ContextMenuContent>
  </ContextMenu>
  <slot v-else />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useClipboard } from '@vueuse/core'
import { toast } from 'vue-sonner'
import { useRawObjectDialog } from '@/composables/useRawObjectDialog'
import {
  ContextMenu,
  ContextMenuTrigger,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuShortcut,
} from '@/components/ui/context-menu'
import { InfoIcon } from 'lucide-vue-next'

interface Props {
  data: any
  showRawData?: boolean
  showCopyId?: boolean
  title?: string
}

const props = withDefaults(defineProps<Props>(), {
  showRawData: true,
  showCopyId: true,
  title: 'Raw Object Data'
})

const authStore = useAuthStore()
const { copy } = useClipboard()
const { showRawObject } = useRawObjectDialog()

const isAdmin = computed(() => authStore.isAdmin)

const showRawObjectData = async () => {
  await showRawObject({
    title: props.title,
    object: props.data
  })
}

const copyId = () => {
  if (props.data?.id) {
    copy(props.data.id)
    toast.success(`ID '${props.data.id}' copied to clipboard`)
  }
}
</script> 