/*!
=========================================================
* Orderli Dashboard - v0.1.3
=========================================================
*/

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query'
import App from './App.vue'
import router from './routes/starterRouter'
import axios from 'axios'
import { useAuthStore } from '@/stores/auth'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { getIdsFromQueryParams } from '@/plugins/processQueryParams'
import DashboardPlugin from './plugins/dashboard-plugin'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

// Styles
import '@/assets/css/nprogress.css'
import '@/assets/index.css'

// Create Vue Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

// Axios setup
axios.defaults.baseURL = import.meta.env.VITE_APP_SERVER_URL

const token = localStorage.getItem('jwt')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// Axios interceptor for handling 401 errors
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      const authStore = useAuthStore()
      authStore.resetAuthState()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

const app = createApp(App)
const pinia = createPinia()

// Register plugins
app.use(pinia)
app.use(router)
app.use(DashboardPlugin)
app.use(autoAnimatePlugin)
app.use(VueQueryPlugin, { queryClient })

// Initialize auth store and check user status before mounting the app
const authStore = useAuthStore()

// Only initialize if we have a token
if (localStorage.getItem('jwt')) {
  authStore.initialize()
    .catch(error => {
      console.error('Failed to initialize application:', error);
      authStore.resetAuthState();
    })
    .finally(() => {
      mountApp();
    });
} else {
  mountApp();
}

function mountApp() {
  // Process query parameters
  let url = location.href;
  let decodedUrl = decodeURIComponent(url);
  decodedUrl = decodedUrl.replace(/&amp;/g, '&');
  let newQueryPath = getIdsFromQueryParams(new URL(decodedUrl).searchParams);
  
  if (newQueryPath.hasIds) {
    router.replace({
      path: window.location.pathname,
      query: newQueryPath.query,
      hash: window.location.hash
    }).catch(() => {});
  }

  // Mount the app
  app.mount('#app');
}

// Sentry setup (commented out for now)
// if (import.meta.env.VITE_APP_SENTRY_DNS) {
//   Sentry.init({
//     Vue: app,
//     dsn: import.meta.env.VITE_APP_SENTRY_DNS,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//   })
// }