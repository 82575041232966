<script setup lang="ts" generic="TValue">
import type { SelectRootEmits, SelectRootProps } from 'radix-vue'
import { SelectRoot, useForwardPropsEmits } from 'radix-vue'
import { computed } from 'vue'

// Work around since v-model only accepts string value :( until V2
// https://github.com/radix-vue/radix-vue/issues/772
//
// Uses generics that are available in Vue 3.3+ 
// https://logaretm.com/blog/better-inputs-with-generics-select/
const props = defineProps<SelectRootProps & {
  modelValue: TValue | undefined
}>()

const emits = defineEmits<SelectRootEmits & {
  'update:modelValue': [value: TValue | undefined]
}>()

const stringifyValue = (value: any): string => {
  if (typeof value === 'string') return value
  try {
    return JSON.stringify(value)
  } catch {
    return String(value)
  }
}

const parseValue = (value: string): any => {
  try {
    return JSON.parse(value)
  } catch {
    return value
  }
}

const model = computed({
  get: () => stringifyValue(props.modelValue),
  set: (value) => emits('update:modelValue', parseValue(value))
})

const forwarded = useForwardPropsEmits(props, emits)
</script>

<template>
  <SelectRoot v-model="model" v-bind="forwarded">
    <slot />
  </SelectRoot>
</template>