<template>
  <div class="notifications">
    <TransitionGroup name="slide-y-up" :duration="transitionDuration">
      <notification
        v-for="notification in notifications"
        :key="notification.timestamp.getTime()"
        v-bind="notification"
        :clickHandler="notification.onClick"
        @close="removeNotification"
      >
      </notification>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import Notification from './Notification.vue'
import { NotificationStore } from './index.js'

const props = defineProps({
  transitionDuration: {
    type: Number,
    default: 200,
  },
  overlap: {
    type: Boolean,
    default: false,
  },
})

const notifications = ref(NotificationStore.state)
const removeNotification = NotificationStore.removeNotification
const settings = NotificationStore.settings

const removeNotificationHandler = (timestamp) => {
  removeNotification(timestamp)
}

watch(() => props.overlap, (newVal) => {
  settings.overlap = newVal
})

onMounted(() => {
  settings.overlap = props.overlap
})
</script>

<style scoped>
.slide-y-up-enter-active,
.slide-y-up-leave-active {
  transition: all v-bind('transitionDuration + "ms"') ease-out;
}
.slide-y-up-enter-from,
.slide-y-up-leave-to {
  opacity: 0;
  transform: translateY(15px);
}
</style>
