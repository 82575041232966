import Notifications from './Notifications.vue'
import { reactive, readonly, ref } from 'vue'

const NotificationStore = {
  state: ref([]), // here the notifications will be added
  settings: reactive({
    overlap: false,
    verticalAlign: 'bottom',
    horizontalAlign: 'left',
    type: 'info',
    timeout: 3000,
    closeOnClick: true,
    showClose: false,
  }),
  setOptions(options) {
    Object.assign(this.settings, options)
  },
  removeNotification(timestamp) {
    const indexToDelete = this.state.value.findIndex((n) => n.timestamp === timestamp)
    if (indexToDelete !== -1) {
      this.state.value.splice(indexToDelete, 1)
    }
  },
  addNotification(notification) {
    if (typeof notification === 'string' || notification instanceof String) {
      notification = { message: notification }
    }
    notification.timestamp = new Date()
    notification.timestamp.setMilliseconds(
      notification.timestamp.getMilliseconds() + this.state.value.length
    )
    notification = { ...this.settings, ...notification }
    this.state.value.push(notification)
  },
  notify(notification) {
    if (this.state.value.length > 2) {
      this.state.value.shift()
    }
    if (Array.isArray(notification)) {
      notification.forEach((notificationInstance) => {
        this.addNotification(notificationInstance)
      })
    } else {
      this.addNotification(notification)
    }
  },
}

const NotificationsPlugin = {
  install(app, options) {
    app.config.globalProperties.$notify = NotificationStore.notify.bind(NotificationStore)
    app.config.globalProperties.$notifications = NotificationStore
    app.component('Notifications', Notifications)

    if (options) {
      NotificationStore.setOptions(options)
    }
  },
}

export { NotificationStore }
export default NotificationsPlugin
