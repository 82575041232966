<template>
  <div :class="cn(containerClass, props.class)" v-if="contentIsVisible">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { provide, type HTMLAttributes, computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { cn } from '@/lib/utils'

interface Props {
  admins?: boolean
  nonAdmins?: boolean
  if?: string | boolean
  role?: string
  class?: HTMLAttributes['class']
}

const props = withDefaults(defineProps<Props>(), {
  admins: false,
  nonAdmins: false,
  if: undefined,
  role: undefined,
  class: undefined
})

const authStore = useAuthStore()

const isAdmin = computed(() => authStore.isAdmin)
const currentRole = computed(() => authStore.currentRole)

const containerClass = computed(() => {
  if (props.admins) return 'admins'
  if (props.nonAdmins) return 'non-admins'
  if (props.role) return `role-${props.role}`
  return ''
})

const contentIsVisible = computed(() => {
  if (props.if !== undefined) {
    if (typeof props.if === 'string') {
      if (!authStore[props.if]) return false
    } else {
      if (!props.if) return false
    }
  }

  if (props.admins) return isAdmin.value
  if (props.nonAdmins) return !isAdmin.value
  if (props.role) return props.role === currentRole.value

  return props.if !== undefined
})
</script>

<style scoped>
.admins {}
.non-admins {}
.role-admin {}
.role-owner {}
.role-manager {}
.role-employee {}
</style>
