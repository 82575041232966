<template>
  <nav class="bg-white border-b" 
       :class="{ '-translate-y-full': !isNavbarVisible, 'translate-y-0': isNavbarVisible }">
    <div class="mx-auto px-2 sm:px-4">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <Button variant="ghost" size="icon" class="md:hidden mr-2" @click="toggleSidebarFunc">
            <MenuIcon class="h-5 w-5" />
          </Button>
          <div class="flex items-center space-x-1 md:space-x-2"> 
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button v-if="isAdmin" variant="ghost" size="icon" @click="reloadWithoutCache">
                    <RefreshCwIcon class="h-3.5 w-3.5" /> 
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Reload accounts without cache</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <nav v-if="currentRestaurant" aria-label="breadcrumb" class="hidden md:block">
              <route-bread-crumb></route-bread-crumb>
            </nav>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button variant="ghost" size="icon" @click="copyCurrentUrl">
                    <LinkIcon class="h-3.5 w-3.5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Copy Link to Current Page</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <Transition>
              <small v-if="copiedToClipboard" class="text-sm text-gray-600">Copied to clipboard</small>
            </Transition>
          </div>
        </div>
        <ul class="flex items-center space-x-2 lg:space-x-3">
          <span v-if="trialDays > 1" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ trialDays }} trial days left
          </span>
          <span v-else-if="trialDays === 1" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ trialDays }} trial day left
          </span>
          <span v-else-if="trialDays < 1 && trialDays > 0" class="px-3 py-1 bg-orange-500 rounded text-white text-xs">
            {{ Math.round((new Date(currentRestaurant.trial_ends_at).getTime() - new Date().getTime()) / 3600000) }}
            trial hours left
          </span>

          <Select v-if="isAdminOrImpersonating" v-model="adminImpersonating" class="w-auto mr-2">
            <SelectTrigger class="w-auto">
              <SelectValue class="pr-1" />
            </SelectTrigger>
            <SelectContent align="end">
              <SelectItem v-for="role in roles" :key="role.value" :value="role.value">
                View <span class="hidden md:inline-block">dashboard</span> as {{ role.label.toLowerCase() }}
              </SelectItem>
            </SelectContent>
          </Select>

          <NotificationsWrapper v-if="canManageAccounts || role === 'owner'" />

          <DropdownMenu v-model:open="userDropdownOpen">
            <DropdownMenuTrigger as="div">
              <Button variant="ghost" size="icon" class="relative" :class="{ 'bg-accent': userDropdownOpen }">
                <UserIcon class="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent class="w-56" align="end">
              <DropdownMenuLabel class="font-normal">
                <div class="flex flex-col space-y-1">
                  <p class="text-sm font-medium leading-none">{{ nameOfUser }}</p>
                  <p class="text-xs leading-none text-muted-foreground">
                    {{ currentRoleLabel }}
                  </p>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="userInformationModal(true)">
                <UserIcon class="mr-2 h-4 w-4" />
                <span>Edit profile</span>
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem @click="logout">
                <LogOutIcon class="mr-2 h-4 w-4" />
                <span>Log out</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </ul>
      </div>
    </div>
    <EditUserInformationModal
      :show="showUserInformationModal"
      @modalClose="userInformationModal(false)"
    />
  </nav>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Button } from '@/components/ui/button'
import { MenuIcon, UserIcon, LogOutIcon, RefreshCwIcon, LinkIcon } from 'lucide-vue-next'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb.vue'
import EditUserInformationModal from '@/views/Settings/EditUserInformationModal.vue'
import NotificationsWrapper from '@/components/NotificationBell/NotificationsWrapper.vue'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator } from '@/components/ui/dropdown-menu'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

import { useAuthStore } from '@/stores/auth'
import { useAccountsStore } from '@/stores/accounts'
import { useRestaurantsStore } from '@/stores/restaurants'

const props = defineProps({
  isNavbarVisible: {
    type: Boolean,
    default: true,
  },
  navbarHeight: {
    type: Number,
    default: 64,
  },
})

const emit = defineEmits(['toggle-sidebar'])

const router = useRouter()
const route = useRoute()

const authStore = useAuthStore()
const accountsStore = useAccountsStore()
const restaurantsStore = useRestaurantsStore()

const showUserInformationModal = ref(false)
const userDropdownOpen = ref(false)
const copiedToClipboard = ref(false)
const trialDays = ref(0)

const currentRestaurant = computed(() => restaurantsStore.currentRestaurant)
const nameOfUser = computed(() => authStore.userFullName || '')
const isAdmin = computed(() => authStore.isAdmin)
const isAdminOrImpersonating = computed(() => isAdmin.value || authStore.adminImpersonateRole)
const canManageAccounts = computed(() => authStore.canManageAccounts)
const role = computed(() => authStore.currentRole)
const currentRoleLabel = computed(() => {
  if (isAdmin.value) return 'Admin'
  return authStore.currentRole?.charAt(0).toUpperCase() + authStore.currentRole?.slice(1)
})

const adminImpersonating = computed({
  get: () => authStore.adminImpersonateRole || 'admin',
  set: (value) => {
    authStore.setAdminImpersonateRole(value === 'admin' ? null : value)
  }
})

const roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'owner', label: 'Owner' },
  { value: 'manager', label: 'Manager' },
  { value: 'staff', label: 'Staff' },
]

const accountId = computed(() => authStore.accountId)
const restaurantId = computed(() => authStore.restaurantId)

const toggleSidebarFunc = inject('toggleSidebar')

watch(currentRestaurant, () => {
  if (!currentRestaurant.value || !currentRestaurant.value.trial_ends_at) return
  const currentDate = new Date()
  const trialEndDate = new Date(currentRestaurant.value.trial_ends_at)
  const timeDifference = trialEndDate.getTime() - currentDate.getTime()
  trialDays.value = Math.round(timeDifference / (1000 * 3600 * 24))
})

function userInformationModal(value) {
  showUserInformationModal.value = value
}

function logout() {
  authStore.logout()
    .then(() => {
      console.log('logout successful')
    })
    .catch((err) => alert(err))
}

function reloadWithoutCache() {
  accountsStore.clearAccountCache()
  accountsStore.getAccounts(false)
    .then(() => {
      alert('Cleared cache & reload successful, all account data should be up-to-date now')
    })
    .catch((err) => alert(err))
}

function copyCurrentUrl() {
  const accountAndRestaurantString = `accountId=${accountId.value}&restaurantId=${restaurantId.value}`
  const query = window.location.search
    ? `${window.location.search}&${accountAndRestaurantString}`
    : `?${accountAndRestaurantString}`

  navigator.clipboard
    .writeText(
      window.location.origin +
        window.location.pathname +
        query +
        window.location.hash
    )
    .then(
      () => {
        copiedToClipboard.value = true
        setTimeout(() => {
          copiedToClipboard.value = false
        }, 2500)
      },
      (err) => {
        console.error('Could not copy text: ', err)
      }
    )
}

watch(adminImpersonating, (newValue) => {
  authStore.setAdminImpersonateRole(newValue === 'admin' ? null : newValue)
})
</script>

<style scoped>
nav {
  transition: transform 0.3s ease-in-out;
}
</style>
