<template>
  <div>
    <div class="container-fluid mt--1 p-4">
      <div class="">
        <br />
        <h2>{{ goodDayMessage }}, {{ userFirstName }} 👋🏼</h2>
        <br />
        <p class="font-normal">
          Welcome to the Orderli dashboard. If you have any questions, feel free to reach out through the chat at the bottom right.
        </p>
        <br />
      </div>

      <div class="row">
        <div class="col-md-6">
          <Card v-if="mandateGiven === false && (canManageAccounts || role === 'owner')">
            <CardHeader>
              <CardTitle>⚠️ Mandate not completed</CardTitle>
            </CardHeader>
            <CardContent>
              <div>
                A mandate for a SEPA Direct Debit is required to use Orderli.
                Please configure the mandate in billing settings. We use SEPA
                Direct Debit to collect the monthly subscription fee of Orderli
                and the transaction costs of any online payments done through
                Orderli.
              </div>
            </CardContent>
            <CardFooter>
              <Button @click="$router.push('/settings#billing')" variant="default" class="float-right">
                Go to settings
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>

      <Only admins>
        <div class="row mt-4">
          <div class="col-12 col-lg-6">
            <Card>
              <CardHeader class="bg-transparent">
                <div class="row items-center w-100">
                  <div class="col">
                    <h6 class="uppercase text-muted-foreground ls-1 mb-1">
                      Recommendations
                    </h6>
                    <CardTitle>Cross sell products</CardTitle>
                  </div>
                  <div class="col-12 pt-3">
                    <unlock-feature feature="cross_selling">
                      <RecommendationsTable :showCompact="true" />
                    </unlock-feature>
                  </div>
                </div>
              </CardHeader>
            </Card>
          </div>
        </div>
      </Only>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import RecommendationsTable from '@/views/Products/RecommendationsTable'
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import Only from '@/components/Only'

import { useAuthStore } from '@/stores/auth'
import { useAccountsStore } from '@/stores/accounts'
import { useBillingStore } from '@/stores/billing'

const router = useRouter()


const authStore = useAuthStore()
const accountsStore = useAccountsStore()
const billingStore = useBillingStore()

const userFirstName = computed(() => authStore.userFirstName)
const goodDayMessage = computed(() => {
  let hour = new Date().getHours()
  return (
    'Good ' +
    ((hour < 12 && hour >= 5 && 'morning') ||
      (hour < 18 && hour >= 12 && 'afternoon') ||
      (hour < 24 && hour >= 18 && 'evening') ||
      'night')
  )
})
const canManageAccounts = computed(() => authStore.canManageAccounts)
const mandateGiven = computed(() => billingStore.mandateGiven)
const role = computed(() => authStore.currentRole)
</script>
