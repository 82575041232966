<template>
  <div class="py-0.5">
    <a
      v-if="!link.children || link.children.length === 0"
      :href="link.external ? link.path : undefined"
      :to="!link.external ? link.path : undefined"
      :target="link.external ? '_blank' : undefined"
      :class="[
        'flex items-center w-full px-2 transition-all duration-300 ease-in-out rounded-md min-h-8',
        isActive ? 'bg-accent text-accent-foreground' : 'hover:bg-accent hover:text-accent-foreground',
        link.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
      ]"
      @click="navigate"
    >
      <div v-if="link.icon" class="w-6 h-6 flex items-center justify-center">
        <component v-if="isLucideIcon" :is="link.icon" class="w-4 h-4 flex-shrink-0" />
        <font-awesome-icon v-else-if="link.icon && link.icon.icon" :icon="link.icon.icon" class="w-4 h-4 flex-shrink-0" />
      </div>
      <span class="text-sm leading-5 whitespace-nowrap transition-all duration-200 ease-in-out overflow-hidden" 
        :style="{ 
          marginLeft: isMinimized && !isHovered ? '0' : '0.5rem',
          width: isMinimized && !isHovered ? '0' : '80%',
          opacity: isMinimized && !isHovered ? 0 : 1,
          visibility: isMinimized && !isHovered ? 'hidden' : 'visible',
          transitionDelay: isMinimized && !isHovered ? '0s' : '0.1s'
        }"
      >{{ link.name }}</span>
    </a>
    <Collapsible v-else>
      <CollapsibleTrigger
        :class="[
          'flex items-center w-full px-2 transition-all duration-300 ease-in-out rounded-md min-h-8',
          isMenuOpen ? 'bg-accent' : 'hover:bg-accent hover:text-accent-foreground',
          'cursor-pointer',
        ]"
        @click="toggleMenu"
      >
        <div class="w-6 h-6 flex items-center justify-center">
          <component v-if="isLucideIcon" :is="link.icon" class="w-4 h-4 flex-shrink-0" />
          <font-awesome-icon v-else-if="link.icon && link.icon.icon" :icon="link.icon.icon" class="w-4 h-4 flex-shrink-0" />
        </div>
        <span class="text-sm leading-5 whitespace-nowrap transition-all duration-200 ease-in-out overflow-hidden" 
          :style="{ 
           marginLeft: isMinimized && !isHovered ? '0' : '0.5rem',
            maxWidth: isMinimized && !isHovered ? '0' : '80%', 
            opacity: isMinimized && !isHovered ? 0 : 1,
            visibility: isMinimized && !isHovered ? 'hidden' : 'visible',
            transitionDelay: isMinimized && !isHovered ? '0s' : '0.1s'
          }"
        >{{ link.name }}</span>
        <ChevronDown class="ml-auto h-4 w-4 transition-all duration-200 ease-in-out" :class="{ 'transform rotate-180': isMenuOpen }"
          :style="{ 
            maxWidth: isMinimized && !isHovered ? '0' : '80%', 
            opacity: isMinimized && !isHovered ? 0 : 1,
            visibility: isMinimized && !isHovered ? 'hidden' : 'visible',
            transitionDelay: isMinimized && !isHovered ? '0s' : '0.1s'
          }"
         />
      </CollapsibleTrigger>
      <CollapsibleContent v-if="!isMinimized || isHovered">
        <div class="pl-6 mt-0.5 relative">
          <div class="absolute left-[1.2rem] top-2 bottom-2 w-px bg-muted-foreground/30"></div>
          <div
            v-for="(childLink, index) in link.children"
            :key="childLink.name + index"
            class="relative py-0.25"
          >
            <!-- <div class="absolute left-[-10px] top-[10px] w-[14px] h-[8px] border-l border-b border-muted-foreground rounded-bl-lg"></div> -->
            <SidebarItem
              :link="childLink"
              :is-minimized="isMinimized"
              :is-hovered="isHovered"
            />
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from '@/components/ui/collapsible'
import { ChevronDown } from 'lucide-vue-next'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  link: {
    type: Object,
    required: true
  },
  isMinimized: {
    type: Boolean,
    default: false
  },
  isHovered: {
    type: Boolean,
    default: false
  }
})

const route = useRoute()
const router = useRouter()

const isActive = computed(() => {
  if (props.link.path === '') return false
  return route.path.startsWith(props.link.path)
})

const isLucideIcon = computed(() => {
  return typeof props.link.icon === 'function' || (typeof props.link.icon === 'object' && !props.link.icon.icon)
})

const isMenuOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const navigate = (event) => {
  if (props.link.disabled || props.link.external) return
  event.preventDefault()
  if (props.link.path) {
    router.push(props.link.path)
  }
}
</script>